import React from 'react';
import { Link, graphql } from 'gatsby';
import _ from 'lodash';

import DefaultLayout from '../components/layout';
import SEO from '../components/seo';
import PostCover from '../components/PostCover';
import Disqus from '../components/Disqus';
import SocialLinks from '../components/SocialLinks';
import PostNavigation from '../components/PostNavigation';

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: true
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (window.innerWidth >= 640) {
      this.setState({ mobile: false });
    } else {
      this.setState({ mobile: true });
    }
  }

  render() {
    const { mobile } = this.state;
    const post = this.props.data.markdownRemark;
    const { prevslug, nextslug, prevtitle, nexttitle, slug } = this.props.pageContext;
    const coverHeight = mobile ? 180 : 350;
    const coverWidth = mobile ? '68%' : '100%';

    return (
      <DefaultLayout>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <article className="article-page"
          style={{
            backgroundColor: 'var(--bg)',
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out',
          }}
        >
          <div className="page-content">
            <PostNavigation
                prevSlug={prevslug}
                prevTitle={prevtitle}
                nextSlug={nextslug}
                nextTitle={nexttitle}
                mobile={mobile}
            />
            {post.frontmatter.cover && (
              <div className="page-cover-image">
                <figure>
                  <PostCover
                    postNode={post}
                    coverHeight={coverHeight}
                    coverWidth={coverWidth}
                  />
                </figure>
              </div>
            )}
            <div className="wrap-content">
              <header className="header-page">
                <h1 className="page-title">{post.frontmatter.title}</h1>
                <div className="page-date">
                  <span>{post.frontmatter.date}</span>
                </div>
              </header>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className="page-footer">
                <div className="page-tag">
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.map(tag => (
                      <Link className="original-tag" key={tag} to={`/tags/${_.kebabCase(tag)}`}>
                        {tag}
                      </Link>
                    ))}
                </div>
              </div>
              <SocialLinks
                  postPath={slug}
                  postNode={post}
                  mobile={mobile}
              />
              <PostNavigation
                prevSlug={prevslug}
                prevTitle={prevtitle}
                nextSlug={nextslug}
                nextTitle={nexttitle}
                mobile={mobile}
              />
              <Disqus postNode={post} />
            </div>
          </div>
        </article>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY, MMM DD")
        tags
        cover
      }
      fields {
        slug
      }
    }
  }
`
