import React from "react";
import { Link } from "gatsby";

const PostNavigation =({
    prevSlug,
    prevTitle,
    nextSlug,
    nextTitle,
    mobile
}) => {
    return (
        <div className="post-navigation">
            <Link to={prevSlug} rel="prev" className="newer-posts"
            style={{
                backgroundColor: 'var(--bg)',
                color: 'var(--textNormal)',
                transition: 'color 0.2s ease-out, background 0.2s ease-out',
              }}
            >
                ← Previous
                {!mobile && <p>{prevTitle}</p>}
            </Link>
            <Link to={nextSlug} rel="next" className="older-posts align-end"
            style={{
                backgroundColor: 'var(--bg)',
                color: 'var(--textNormal)',
                transition: 'color 0.2s ease-out, background 0.2s ease-out',
              }}
            >
                Next →
                {!mobile && <p>{nextTitle}</p>}
            </Link>
        </div>
    )
}

export default PostNavigation;